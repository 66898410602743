import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

const DEFAULT_BORDER_COLOR = '#04041514';

export const AVATAR_SIZES = {
  default: { fontSize: 16, diameter: 48 },
  xs: { fontSize: 10, diameter: 24 },
  sm: { fontSize: 12, diameter: 32 },
  md: { fontSize: 16, diameter: 56 },
  lg: { fontSize: 20, diameter: 64 },
  xl: { fontSize: 20, diameter: 72 }
};

const AVATAR_COLORS = [
  // Branch
  { background: '#f7f3ef', color: '#322010' },
  // Stroll
  { background: '#e4f4e8', color: '#032519' },
  // Rubber
  { background: '#f6e8e7', color: '#450c1d' },
  // Tennis
  { background: '#f8fceb', color: '#37392a' },
  // Blue
  { background: '#edf7ff', color: '#091159' }
];

const DEFAULT_SHAPE = 'round';
const SHAPES = {
  round: 'br-100',
  square: 'br2'
};

export default function UserAvatar({ user, size, shape, className }) {
  const { fontSize, diameter } = AVATAR_SIZES[size] || AVATAR_SIZES.default;
  const shapeStyles = SHAPES[shape || DEFAULT_SHAPE];

  const classes = classnames(
    'UserAvatar font-serif fw-medium flex items-center justify-center tc ttu ba',
    shapeStyles,
    className
  );

  const styles = {
    width: diameter,
    height: diameter,
    borderColor: DEFAULT_BORDER_COLOR
  };

  if (!user) {
    return (
      <div className={classes} style={styles}>
        <Icon name="fetch-profile" className="purple" />
      </div>
    );
  }

  const colorIndex = (user.id || 0) % AVATAR_COLORS.length;
  const { background, color } = AVATAR_COLORS[colorIndex];

  return (
    <div
      className={classes}
      style={{
        ...styles,
        background,
        color,
        fontSize,
        fontWeight: 500
      }}
    >
      {user.profile_photo_url ? (
        <SmartImage
          Tag="div"
          src={user.profile_photo_url}
          className={classnames('bg-center cover h-100 w-100', shapeStyles)}
        />
      ) : (
        user.initials
      )}
    </div>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    initials: PropTypes.string,
    profile_photo_url: PropTypes.string
  }),
  shape: PropTypes.oneOf(Object.keys(SHAPES)),
  size: PropTypes.oneOf(Object.keys(AVATAR_SIZES)),
  className: PropTypes.string
};

UserAvatar.defaultProps = {
  size: 'default',
  className: null,
  user: null,
  shape: null
};
